@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@font-face {
    font-family: Montserrat-bold;
    src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
    font-family: "Montserrat-light";
    src: url("./assets/fonts/Montserrat-LightItalic.ttf");
}

@font-face {
    font-family: Montserrat-Regular;
    src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Montserrat-SemiBold;
    src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: "Montserrat-Regular", Calibri;
    @apply bg-gray-50
}


.hideScroll::-webkit-scrollbar {
    width: 0 !important;
    display: none;
}
