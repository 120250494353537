
.table_head {
    @apply text-[14px] border-b-2 text-left text-black font-normal bg-gray-100;
}
.table_head>th {
    @apply px-2 py-2;
}

.table_row {
    @apply bg-white border-b text-left cursor-pointer hover:bg-gray-50 ;
}
.table_row>td {
    @apply px-2 py-2;
}
